/*
 * Copyright© Celligence International, LLC - 2023
 * 
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 * 
 */

.banner,
.banner a {
  background-repeat: no-repeat;
  text-align: center !important;
  color: #ffff;
  position: relative;

  font-family: "poppins";
}

.contactbutton {
  background: #00aae4;
  border-radius: 20px;
  color: #ffffff;
  border-color: #00aae4;
  font-weight: 600;
  text-decoration: none !important;
}

.contactbutton :hover {
  cursor: pointer;
}
.contactbutton2 {
  background: #00aae4;
  border-radius: 20px;
  color: #ffffff;
  border-color: #00aae4;
  font-weight: 600;
  text-decoration: none !important;
}

.contactbutton2 :hover {
  cursor: pointer;
}

@media screen and (min-width: 992px) {
  .obilefamily {
    display: none;
  }
  .hoarmozisx{
    display: none;
  }
  .baseline {
    
      position: relative;
      max-width: 40%;
      margin-top: -6rem;
      margin-left: 16rem;

  }

  .contactbutton2 {
    display: none;
  }
  .mobilecoupleiage {
    display: none;
  }
  .calfhaprogram {
    position: relative;
    margin-top: -8rem;
  }
  .trueapprovehead {
    color: #00aae4;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    text-align: justify;
    font-size: 1.4rem;
  }
  .dreamtext {
    color: #083f88;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    text-align: justify;
    font-size: 1.1rem;
  }
  .breakingbarrirests {
    text-align: left;
  }
  .mobilejoq {
    display: none;
  }
  .coyplesection {
    margin-top: -7rem;
  }
  .joainfomobile2 {
    display: none;
  }
  .closebuttnmobile {
    display: none;
  }
  .joaquinsec {
    font-size: 0.8rem;
  }
  .joaquinrow {
    position: relative;
    top: 10rem;
  }

  .introtext {
    margin-top: 6rem;
  }

  .edvin {
    width: 50%;
  }

  .namejo2 {
    padding-bottom: 2rem;
  }
  .banner {
    background-image: url("https://stagingresources.swmc.com/keith/bannerrepeat.png");
    padding-bottom: 14rem;
    background-position: 100% 0;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .harmozid {
    text-align: justify;
    font-family: "Poppins";
    color: #fff;
    font-size: 1.4rem;
    position: relative;
    top: 3.5rem;
    font-weight: 600;
  }
  .joaquinsec {
    background-image: url("https://stagingresources.swmc.com/brokerbrk/blackbackground.png");
  }

  .namejo2 a {
    text-decoration: underline;
  }
  .bannertextdesktop {
    text-align: left !important;
    font-family: "Poppins";
    font-size: 2.3rem;
    font-style: normal;
    font-weight: 600;
    margin-top: 11rem;
    border-left: 6px solid #00aae4;
    z-index: 2;
    position: relative;
    font-family: "Poppins" !important;
    color: #ffffff;
  }
}
@media screen and (min-width: 320px) and (max-width: 375px) {
  .bannertextdesktop {
    font-size: 1.4rem;
    font-weight: 700;
    margin-top: 5rem;
    /* line-height: 1.2; */
    /* padding: 0 2rem; */
    font-family: "Poppins" !important;
    color: #ffffff;
  }
}

@media screen and (max-width: 991.7px) {
  .banner {
    background-image: url("https://stagingresources.swmc.com/keith/mobileupdatedbacground.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .hoarmozisx{
    background: linear-gradient(90.1deg, #EE581D 2.96%, #40005E 98.65%);
text-align: center;
padding-bottom: 4rem;
  }
  .hoarmozis{
    color: #FFF;
   font-family: "Poppins";
   font-weight: 600;
   margin-top: 2rem;
   font-size: 1.2rem;

  }
  .harmozid {
    display: none;
  }
  .clalfatext {
    font-size: 3rem !important;
  }
  .baseline {
    position: relative;
    max-width: 40%;
    display: flex;
    margin-top: -2rem;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
  }
  .contactbutton {
    display: none;
  }
  .trueapprovehead {
    color: #00aae4;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    text-align: justify;
    font-size: 1.3rem;
    list-style: none;
    margin-top: 2rem;
  }
  .dreamtext {
    color: #083f88;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    text-align: justify;
    font-size: 1.2rem;
  }
  .coupleimge {
    display: none !important;
  }
  .breakingbarrirests {
    margin-top: 34rem;
  }
  .mobilecoupleiage {
    max-width: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  .breaktxt {
    font-family: "poppins";
    font-size: 1.4rem !important;
    color: #ffffff;
    font-weight: 600;
  }
  .joainfomobile2 p {
    color: #fff;
    font-weight: 500;
    font-size: 0.9rem !important;
    line-height: 1.2rem;
  }

  .joainfomobile2 a {
    color: #fff;
    font-weight: 500;
    font-size: 0.9rem !important;
    line-height: 1.2rem;
  }
  .namejo2 {
    display: none;
  }
  .namejo2 {
    display: none;
  }

  .closebuttn {
    display: none;
  }
  .closebuttnmobile {
    padding-bottom: 2rem;
  }
  .mortgageofficer {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .mortgageofficer {
    font-size: 1.4rem !important;
  }

  .namejo2 {
    font-size: 0.9rem !important;
  }

  .bannertextdesktop {
    font-size: 1.7rem;
    font-weight: 600;
    margin-top: 5rem;
    /* line-height: 1.2; */
    /* padding: 0 2rem; */
    font-family: "Poppins" !important;
    color: #ffffff;
  }
}
.coupleimge {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}
@font-face {
  font-family: "Praise-Regular";
  src: local("Praise-Regular"),
    url("../fonts/Praise-Regular.ttf") format("truetype");
  font-display: swap;
}
@media screen and (min-width: 1700px) {
  .banner {
    background-position: center !important;
    padding-top: 4rem;
  }
}

@media screen and (min-width: 992px) and (max-width: 1699px) {
  .banner {
    background-size: 100% 100%;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .namejo2 {
    font-size: 0.7rem;
  }
  .clalfatext{
    font-size: 4rem!important;
  }

  .harmozid {
    text-align: justify;
    font-family: "Poppins";
    color: #fff;
    font-size: 1.3rem!important;
    position: relative;
    top: 7.5rem !important;
    font-weight: 600;
}
  .trueapprovehead {
    font-size: 1.1rem;
  }
  .dreamtext {
    font-size: 1rem;
  }
  .banner {
    padding-bottom: 19rem !important;
  }
  .bannertextdesktop {
    font-size: 1.9rem;
  }
}
@media screen and (min-width: 1400px) and (max-width: 1699px) {
  .banner {
    padding-bottom: 13rem;
  }
}

.mortgageofficer {
  color: #ffffff;
  background: #00aae4;
  font-family: "Poppins";
  font-weight: 600;
  text-align: center;
  font-size: 1.6rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.breaktxt {
  font-family: "poppins";
  font-size: 2rem;
  color: #ffffff;
  font-weight: 600;
}
.clalfatext {
  font-family: "Praise-Regular";
  font-size: 5rem;
  background: linear-gradient(89.62deg, #ff6b00 32.5%, #ffb800 82.88%);
  -webkit-background-clip: text;
  color: transparent;
}
.clalfatextspan {
  font-family: "poppins";
  font-size: 4rem;
  color: #00aae4;
  font-weight: 600;
}

@font-face {
  font-family: "Satisfy-Regular";
  src: local("Satisfy-Regular"),
    url("../../components/fonts/Satisfy-Regular.ttf") format("truetype");
  font-display: swap;
}
.dontwaste {
  font-family: "Satisfy-Regular";
  font-weight: 400;
  font-size: 2rem;
}
