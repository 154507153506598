
@font-face {
    font-family: 'handycheera';
    font-weight: bold;
    src: url('./components/fonts/Handycheera_Regular.otf');
    font-display: swap;
  }
  @font-face {
    font-family: 'Flood';
    font-weight: bold;
    src: url('./components/fonts/Flood.otf');
    
  }
  @font-face {
    font-family: 'CenturyGothic';
    src: local('CenturyGothic'), url('./components/fonts/Century-Gothic.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'CenturyGothicBold';
    src: local('CenturyGothicBold'), url('./components/fonts/Century-Gothic.ttf') format('truetype');
    font-display: swap;
}

@font-face {
  font-family: 'OPTIEngraversOldEnglish\ Medium';
  src: local('OPTIEngraversOldEnglish\ Medium'), url('./components/fonts/OPTIEngraversOldEnglish\ Medium.ttf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Satisfy-Regular';
  src: local('Satisfy-Regular'), url('./components/fonts/Satisfy-Regular.ttf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Praise-Regular';
  src: local('Praise-Regular'), url('./components/fonts/Praise-Regular.ttf') format('truetype');
  font-display: swap;
}

#rateQuote .rate-title-bg-color {
  background-color: #00AAE4!important;
}