@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,100&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


@media only screen and (width: 320px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 5px !important;
    left: 235px !important;
  }
}

@media only screen and (min-width: 321px) and (max-width: 350.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 5px !important;
    left: 255px !important;
  }
}

@media only screen and (min-width: 351px) and (max-width: 374.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 5px !important;
    left: 270px !important;
  }
}

@media only screen and (min-width: 375px) and (max-width: 424.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 5px !important;
    left: 280px !important;
  }
}

@media only screen and (min-width: 425px) and (max-width: 600.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 5px !important;
    left: 300px !important;
  }
}

@media only screen and (min-width: 601px) and (max-width: 660.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 15px !important;
    left: 320px !important;
  }
}

@media only screen and (min-width: 661px) and (max-width: 710.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 15px !important;
    left: 340px !important;
  }
}

@media only screen and (min-width: 711px) and (max-width: 740.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 15px !important;
    left: 360px !important;
  }
}

@media only screen and (min-width: 741px) and (max-width: 767.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 15px !important;
    left: 380px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 820.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 15px !important;
    left: 320px !important;
  }
}

@media only screen and (min-width: 821px) and (max-width: 900.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 15px !important;
    left: 350px !important;
  }
}

@media only screen and (min-width: 901px) and (max-width: 960.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 15px !important;
    left: 380px !important;
  }
}

@media only screen and (min-width: 961px) and (max-width: 991.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 15px !important;
    left: 400px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1023.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 20px !important;
    left: 346px !important;
  }
}



/* if device has a touch screen */
@media (any-pointer: coarse) {
  @media only screen and (min-width: 1024px) and (max-width: 1040.7px) {
    body .uwy.userway_p3 .uai {
      top: 20px !important;
      left: 366px !important;
    }
  }
}

/* if device has no touch screen */
@media (any-pointer: fine) {
  @media only screen and (min-width: 1024px) and (max-width: 1040.7px) {
    body .uwy.userway_p3 .uai {
      top: 20px !important;
      left: 366px !important;
    }
  }
}
@media only screen and (min-width: 1040.7px) and (max-width: 1099.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 20px !important;
    left: 365px !important;
  }
}

@media only screen and (min-width: 1100px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 20px !important;
    left:500px !important;
  }
}