/*
 * Copyright© Celligence International, LLC - 2023
 * 
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 * 
 */
 .userWayContainer {
  margin-top: 0.3rem !important;
  margin-left: 1rem !important;
}
.userWayBackGroundBody {
  cursor: pointer;
  background: rgb(8, 18, 45) !important;
  border: none;
  border-radius: 50% !important;
  padding: 3px;
  display: inline-block;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  /* box-sizing: content-box !important; */
}

.userWayBackGroundBody:hover {
    transition: transform .4s!important;
    transform: scale(1.25);
}

@media only screen and (min-width: 320px) and (max-width: 358.7px) {
  .userWayContainer {
    margin-left: 1rem !important;
  }
  .main_logo{
    margin-left: 2.5rem !important;
 
  }
}

@media only screen and (min-width: 351px) and (max-width: 400.7px) {
  .userWayContainer {
    margin-left: 0.9rem !important;
    width: 20px!important ;
  }
}